import React, { useState } from 'react';

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white p-4 shadow-lg rounded-lg mb-4">
      <button 
        className="w-full text-left text-lg font-semibold"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
}

function FAQs() {
  const faqs = [
    { question: '* How can I get involved with your organization?', answer: 'You can get involved by volunteering your time, participating in events, or donating to our cause.' },
    { question: '* What types of donations do you accept?', answer: 'We accept both monetary donations and in-kind contributions such as food, clothing, and supplies.' },
    { question: '* Do you have any upcoming events I can attend?', answer: 'Yes! We have several events throughout the year. Please check our programs page for the latest information.' },
    { question: '* How do you ensure that donations are used effectively?', answer: 'We have a transparent reporting system and regularly publish our financial statements to show how funds are allocated.' },
    { question: '* Can I volunteer for a specific program?', answer: 'Absolutely! You can sign up for specific programs that align with your interests and skills. Just fill out our volunteer application form.' },
    { question: '* What are the benefits of volunteering?', answer: 'Volunteering offers you a chance to give back to the community, develop new skills, and meet like-minded individuals.' },
    { question: '* How can I contact your team for more questions?', answer: 'You can reach us via the contact form on our website or directly at rvcofoundations@gmail.com.' },
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">FAQs</h2>
        <div>
          {faqs.map((faq, index) => (
            <FAQItem key={index} {...faq} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQs;
