import React from 'react';

function SiteMap() {
  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Site Map 🗺</h2>
        <div className="max-w-2xl mx-auto bg-white p-8 shadow-lg rounded-lg">
          <br />
          <p className="text-gray-700 mb-6 text-center">
            ✔ Explore all the pages on our website easily using the structured navigation below.
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <br />
            <li className="mb-4">
              <strong>1️⃣ Home</strong> - Overview of the foundation's mission, vision, and key programs.
            </li>
            <br />
            <li className="mb-4">
              <strong>2️⃣ About Us</strong> - Learn more about our history, team, and partners.
            </li>
            <br />
            <li className="mb-4">
              <strong>3️⃣ Programs & Services</strong> - Overview of the services we offer, including detailed pages for each program and impact stories.
            </li>
            <br />
            <li className="mb-4">
              <strong>4️⃣ Get Involved</strong> - Find ways to volunteer, donate, and participate in events.
            </li>
            <br />
            <li className="mb-4">
              <strong>5️⃣ Resources</strong> - Access publications, educational materials, and FAQs.
            </li>
            <br />
            <li className="mb-4">
              <strong>6️⃣ News & Updates</strong> - Read our blog articles, press releases, and subscribe to our newsletter.
            </li>
            <br />
            <li className="mb-4">
              <strong>7️⃣ Contact Us</strong> - Get in touch through our contact form or find our contact information and social media links.
            </li>
            <br />
            <li className="mb-4">
              <strong>8️⃣ Privacy Policy & Terms of Service</strong> - Understand our data handling policies and the rules for using our website.
            </li>
            <br />
            <li className="mb-4">
              <strong>9️⃣ Site Map</strong> - You're here!
            </li>
            <br />
            <br />
            <br />
            <br />
            <br />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SiteMap;
