import React, { useState } from 'react';
import scholarshipForm from '../static/scholarship-form.pdf';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HomePage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async(e) => {
    e.preventDefault()

    try {
      const response = await fetch('https://rvcof-backend.onrender.com/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      })

      const data = await response.json();

      if(response.ok) {
        toast.success('Success: ' + data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setEmail('')
      } else {
        toast.error('Error: ' + (data.message || 'Failed to subscribe to newsletter, Please try again later'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error)  
      toast.error('An error occurred, Please try again later', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage('')
    }, 5000)
  }
  return (
    <div className="bg-gray-50 min-h-screen">
        <ToastContainer />

      {/* Hero Section */}
      <section className="bg-blue-900 text-white py-20 mt-16 relative overflow-hidden">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-5xl font-extrabold leading-tight tracking-wide max-w-3xl mx-auto">
            Welcome to Rev Vitalis Chukwuemeka Onah Foundations
          </h1>
          <p className="mt-6 text-lg max-w-2xl mx-auto leading-relaxed text-gray-100">
            We are dedicated to fostering spiritual growth, community outreach, and personal empowerment under the inspiring leadership of Rev Vitalis Onah. Join us in making a difference.
          </p>
          
          <div className="mt-8 flex justify-center space-x-4">
            <a
              href="/donate"
              className="inline-block bg-yellow-500 text-blue-900 font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-yellow-600 hover:shadow-xl transition-transform duration-300 transform hover:-translate-y-1 scale-105 focus:ring-4 focus:ring-yellow-300 focus:ring-opacity-50"
              aria-label="Donate now"
            >
              Donate Now
            </a>
            <a
              href="/about-us"
              className="inline-block bg-transparent border-2 border-white text-white font-semibold py-3 px-6 rounded-full hover:bg-white hover:text-blue-900 transition-colors duration-300 transform hover:scale-105 focus:ring-4 focus:ring-white focus:ring-opacity-50"
              aria-label="Learn more about us"
            >
              Learn More
            </a>
          </div>
        </div>
        
        {/* Decorative Background Elements */}
        <div className="absolute top-0 left-0 w-1/3 h-full bg-gradient-to-br from-blue-800 opacity-50 rounded-full blur-3xl"></div>
        <div className="absolute bottom-0 right-0 w-1/4 h-1/2 bg-gradient-to-tl from-blue-700 opacity-40 rounded-full blur-3xl"></div>
      </section>

      {/* Mission & Vision Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-12 tracking-tight">
            Our Mission and Vision
          </h2>

          <div className="flex flex-wrap justify-center gap-12">
            {/* Mission Section */}
            <div className="w-full md:w-1/2 px-6 md:px-10 py-8 bg-white rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4 tracking-wide">
                Our Mission
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                At Rev Vitalis Chukwuemeka Onah Foundations, our mission is to enrich lives through dedicated spiritual guidance, community service, and educational initiatives.
              </p>
            </div>

            {/* Vision Section */}
            <div className="w-full md:w-1/2 px-6 md:px-10 py-8 bg-white rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4 tracking-wide">
                Our Vision
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                We envision becoming a beacon of hope and transformation, empowering individuals and communities through spiritual growth and compassionate outreach.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Scholarship Form Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
            Scholarship Program Application
          </h2>
          <p className="text-lg text-gray-700 max-w-xl mx-auto mb-10 leading-relaxed">
            We are delighted to offer scholarships to deserving students. If you are interested in applying for our scholarship program, please download the form below.
          </p>
          
          <a
            href={scholarshipForm}
            download
            className="inline-flex items-center bg-blue-900 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 hover:shadow-xl transform transition-transform duration-300"
          >
            <span className="mr-2">📄</span>
            Download Scholarship Form
          </a>
        </div>
      </section>

      {/* Our Impact Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-extrabold text-center text-blue-900 mb-10">Our Impact</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            
            {/* Community Outreach */}
            <div className="bg-white border-t-4 border-blue-900 rounded-lg p-6 shadow-lg transform transition-transform hover:scale-105">
              <div className="flex items-center mb-4">
                <span className="text-blue-900 text-4xl mr-4">🌍</span>
                <h3 className="text-2xl font-bold text-blue-900">Community Outreach</h3>
              </div>
              <p className="text-gray-600">
                Our outreach programs have positively impacted many lives, offering essential support, food, and clothing to those in need.
              </p>
            </div>
            
            {/* Scholarships Awarded */}
            <div className="bg-white border-t-4 border-yellow-500 rounded-lg p-6 shadow-lg transform transition-transform hover:scale-105">
              <div className="flex items-center mb-4">
                <span className="text-yellow-500 text-4xl mr-4">🎓</span>
                <h3 className="text-2xl font-bold text-yellow-500">Scholarships Awarded</h3>
              </div>
              <p className="text-gray-600">
                We have empowered numerous students through scholarships, enabling them to pursue their educational aspirations.
              </p>
            </div>
            
            {/* Volunteers Engaged */}
            <div className="bg-white border-t-4 border-green-600 rounded-lg p-6 shadow-lg transform transition-transform hover:scale-105">
              <div className="flex items-center mb-4">
                <span className="text-green-600 text-4xl mr-4">🤝</span>
                <h3 className="text-2xl font-bold text-green-600">Volunteers Engaged</h3>
              </div>
              <p className="text-gray-600">
                Our initiatives are fueled by the commitment of several volunteers who generously contribute their time and resources to support our mission.
              </p>
            </div>

          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-extrabold text-blue-900 mb-8">What People Are Saying</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            
            {/* Testimonial 1 */}
            <div className="p-8 bg-white rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
              <div className="flex items-start mb-4">
                <span className="text-blue-500 text-3xl mr-3">“</span>
                <p className="text-lg text-gray-700">
                  "The foundation's scholarship program changed my life. It gave me the opportunity to pursue my education and achieve my dreams."
                </p>
              </div>
              <p className="mt-4 font-semibold text-blue-900">- Scholarship Recipient</p>
            </div>
            
            {/* Testimonial 2 */}
            <div className="p-8 bg-white rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
              <div className="flex items-start mb-4">
                <span className="text-green-500 text-3xl mr-3">“</span>
                <p className="text-lg text-gray-700">
                  "Their outreach programs have been a blessing to our community. We are grateful for the continuous support from the foundation."
                </p>
              </div>
              <p className="mt-4 font-semibold text-green-600">- Community Member</p>
            </div>

          </div>
        </div>
      </section>

      {/* How You Can Help Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-extrabold text-blue-900 mb-6">How You Can Help</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-10">
            Join us in making a lasting impact in the lives of others. Here's how you can get involved with our mission.
          </p>

          <div className="flex flex-wrap justify-center gap-8">
            
            {/* Card 1 - Volunteer */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-gray-100 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
                <div className="flex justify-center mb-4">
                  <span className="text-3xl text-blue-500">🙌</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">Volunteer</h3>
                <p className="text-gray-600 leading-relaxed">
                  Contribute your time and skills to help us serve those in need. Sign up to become a volunteer today.
                </p>
              </div>
            </div>

            {/* Card 2 - Donate */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-gray-100 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
                <div className="flex justify-center mb-4">
                  <span className="text-3xl text-green-500">💖</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">Donate</h3>
                <p className="text-gray-600 leading-relaxed">
                  Your donations help us provide scholarships, food, and resources to those in need. Every little bit helps.
                </p>
              </div>
            </div>

            {/* Card 3 - Spread the Word */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-gray-100 p-8 rounded-lg shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl">
                <div className="flex justify-center mb-4">
                  <span className="text-3xl text-purple-500">📣</span>
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">Spread the Word</h3>
                <p className="text-gray-600 leading-relaxed">
                  Help us reach a wider audience by sharing our mission and programs with your network.
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Newsletter Subscription */}
      <section className="bg-blue-900 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-extrabold mb-4">Stay Updated</h2>
          <p className="text-lg mb-8">
            Subscribe to our newsletter for the latest updates on our programs, initiatives, and events.
          </p>
          <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-4 py-3 rounded-lg border border-gray-300 mb-4 text-blue-900 bg-white"
              required
            />
            <button
              type="submit"
              className="bg-yellow-500 text-blue-900 py-3 px-6 rounded-lg hover:bg-yellow-600 transition duration-300"
            >
              Subscribe
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
