import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  // Function to handle page refresh on link click
  const handleLinkClick = (path) => {
    if (window.location.pathname === path) {
      window.location.reload();
    } else {
      window.location.href = path;
    }
  };

  return (
    <footer className="bg-blue-900 text-white py-8"> {/* Reduced padding here */}
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between space-y-6 md:space-y-0">
          {/* Column 1: Quick Links */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-lg font-bold mb-4">Quick Links</h4>
            <ul>
              <li>
                <a
                  href="/"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/');
                  }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about-us"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/about-us');
                  }}
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/programs"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/programs');
                  }}
                >
                  Programs
                </a>
              </li>
              <li>
                <a
                  href="/get-involved"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/get-involved');
                  }}
                >
                  Get involved
                </a>
              </li>
              <li>
                <a
                  href="/resources"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/resources');
                  }}
                >
                  Resources
                </a>
              </li>
              <li>
                <a
                  href="/news-updates"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/news-updates');
                  }}
                >
                  News updates
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/contact-us');
                  }}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="/privacy-policy-terms"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/privacy-policy-terms');
                  }}
                >
                  Privacy Policy Terms
                </a>
              </li>
              <li>
                <a
                  href="/site-map"
                  className="hover:text-yellow-500"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClick('/site-map');
                  }}
                >
                  Site Map
                </a>
              </li>
            </ul>
          </div>

          {/* Column 2: Contact Info */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-lg font-bold mb-4">Contact Us</h4>
            <p>Email: rvcofoundation@gmail.com</p>
            <p>Phone: +234 805 668 3285</p>
            <p>Address: The New Covenant Catholic Church, 2, ECWA CRESCENT, off Okulaja Street, opposite PEDRO POLICE STATION, Somolu, Lagos 100233, Lagos</p>
          </div>

          {/* Column 3: Social Links */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-lg font-bold mb-4">Follow Us</h4>
            <div className="flex space-x-6">
              <a href="https://www.facebook.com/profile.php?id=61565208497459" className="hover:text-yellow-500">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://x.com/rvcofoundations" className="hover:text-yellow-500">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.instagram.com/rev.vitalisonahfoundations" className="hover:text-yellow-500">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com/in/rvco-foundations/" className="hover:text-yellow-500">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 text-center">
          <p>&copy; {new Date().getFullYear()} Rev Vitalis Chukwuemeka Foundations. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
