import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import scholarshipForm from '../static/scholarship-form.pdf';

function ProgramsPage() {
  // Slider settings for the impact stories
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, // Enables auto sliding
    autoplaySpeed: 3000,
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Overview Section */}
      <section className="bg-blue-900 text-white py-16 mt-4">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold">Our Programs & Services</h1>
          <p className="mt-4 text-lg">Explore the various programs and services we offer to make a positive impact in the community.</p>
        </div>
      </section>

      {/* Detailed Programs Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Our Programs</h2>
          <div className="flex flex-wrap -mx-4">
            {/* Program 1 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://plus.unsplash.com/premium_photo-1683535508596-9216de2ad64b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8U2Nob2xhcnNoaXB8ZW58MHx8MHx8fDA%3D"
                  alt="Scholarships program image"
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4">Scholarship Program</h3>
                <p className="mt-2 text-gray-700">
                  Launched to empower underprivileged students, this program offers financial assistance and mentorship to help them achieve their educational goals.
                  <br />
                  <strong>Key Highlights:</strong>
                  <ul className="list-disc list-inside mt-2 mb-4">
                    <li>Annual scholarships for deserving students.</li>
                    <li>Personalized mentorship and career guidance.</li>
                    <li>Support for academic and career aspirations.</li>
                  </ul>
                  Through this initiative, the foundation aims to create lasting impacts, fostering student growth and community prosperity.
                </p>
                <a
                  href={scholarshipForm}
                  download
                  className="inline-block bg-blue-900 text-white py-3 px-8 rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Download the Scholarship Form
                </a>
              </div>
            </div>

            {/* Program 2 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://images.unsplash.com/photo-1636191246753-7e36bde386a8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8cHJpc29uJTIwb3V0cmVhY2h8ZW58MHx8MHx8fDA%3D"
                  alt="Prison Outreach program image"
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4">Prison Outreach Program</h3>
                <p className="mt-2 text-gray-700">
                  Dedicated to providing spiritual support and hope to incarcerated individuals through regular visits and counseling.
                  <br />
                  <strong>Focus Areas:</strong>
                  <ul className="list-disc list-inside mt-2 mb-4">
                    <li>Personal transformation and emotional healing.</li>
                    <li>Provision of educational resources.</li>
                    <li>Facilitating positive change and reintegration into society.</li>
                  </ul>
                  By extending compassion, this program aims to inspire hope and contribute to rehabilitation efforts.
                </p>
              </div>
            </div>

            {/* Program 3 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://images.unsplash.com/photo-1673280401347-b26a0639b957?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y29tbXVuaXR5JTIwb3V0cmVhY2h8ZW58MHx8MHx8fDA%3D"
                  alt="Community Service Initiatives program image"
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4">Community Service Initiatives</h3>
                <p className="mt-2 text-gray-700">
                  Dedicated to making a tangible impact in local communities through outreach efforts, including:
                  <ul className="list-disc list-inside mt-2 mb-4">
                    <li>Food distribution.</li>
                    <li>Neighborhood clean-ups.</li>
                    <li>Support for community development projects.</li>
                  </ul>
                  These initiatives aim to enhance the quality of life and foster collaboration among community members.
                </p>
              </div>
            </div>

            {/* New Program 4 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://plus.unsplash.com/premium_photo-1725075087008-c00270f7873f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aGVhbHRoJTIwYXdhcm5lc3MlMjBwcm9ncmFtfGVufDB8fDB8fHww"
                  alt="Health Awareness program image"
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4">Health Awareness Campaigns</h3>
                <p className="mt-2 text-gray-700">
                  Aimed at educating communities on health issues and promoting healthy lifestyles.
                  <br />
                  <strong>Key Activities:</strong>
                  <ul className="list-disc list-inside mt-2 mb-4">
                    <li>Free health screenings and check-ups.</li>
                    <li>Workshops on nutrition and wellness.</li>
                    <li>Collaborations with local health professionals.</li>
                  </ul>
                  This program seeks to reduce health disparities and improve overall community health.
                </p>
              </div>
            </div>

            {/* New Program 5 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://images.unsplash.com/photo-1718375505909-00916b0e8598?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dm9jYXRpb25hbCUyMHRyYWluaW5nc3xlbnwwfHwwfHx8MA%3D%3D"
                  alt="Vocational Training program image"
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4">Vocational Training Programs</h3>
                <p className="mt-2 text-gray-700">
                  Providing skills training to empower individuals for better employment opportunities.
                  <br />
                  <strong>Training Focus:</strong>
                  <ul className="list-disc list-inside mt-2 mb-4">
                    <li>Carpentry and woodworking.</li>
                    <li>Culinary arts.</li>
                    <li>Computer programming and IT skills.</li>
                  </ul>
                  This initiative promotes self-sufficiency and economic independence among participants.
                </p>
              </div>
            </div>

            {/* New Program 6 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://plus.unsplash.com/premium_photo-1681494382914-a0f75d8b94be?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8eW91dGglMjBtZW50b3JzaGlwJTIwcHJvZ3JhbXxlbnwwfHwwfHx8MA%3D%3D"
                  alt="Youth Mentorship program image"
                  className="w-full h-48 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4">Youth Mentorship Program</h3>
                <p className="mt-2 text-gray-700">
                  Focused on guiding young individuals through personal and professional development.
                  <br />
                  <strong>Mentorship Aspects:</strong>
                  <ul className="list-disc list-inside mt-2 mb-4">
                    <li>One-on-one mentorship sessions.</li>
                    <li>Workshops on leadership skills.</li>
                    <li>Networking opportunities with professionals.</li>
                  </ul>
                  This program aims to inspire and empower the next generation of leaders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Impact Stories Section with Slider */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Impact Stories</h2>
          <Slider {...sliderSettings}>
            {/* Story 1 */}
            <div className="transition-transform transform hover:scale-105 duration-300">
              <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                <h3 className="text-xl font-semibold mt-4 text-gray-900">Scholarship Success</h3>
                <p className="mt-2 text-gray-600 leading-relaxed">
                  A scholarship recipient pursued a degree in Environmental Science at the University of Lagos with the foundation’s support. Overcoming financial hardships, the student excelled academically and now works with an environmental NGO, driving sustainable practices and advocating for green policies. This journey exemplifies how the foundation’s scholarship program can turn aspirations into achievements.
                </p>
              </div>
            </div>

            {/* Story 2 */}
            <div className="transition-transform transform hover:scale-105 duration-300">
              <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                <h3 className="text-xl font-semibold mt-4 text-gray-900">Transformation Through Outreach</h3>
                <p className="mt-2 text-gray-600 leading-relaxed">
                  A scholarship recipient, awarded a scholarship in recent years, fulfilled the dream of becoming a medical doctor. The financial aid enabled this individual to attend medical school, and they now serve in a rural healthcare facility, providing essential medical services to underserved communities. This story highlights the foundation's role in shaping healthcare leaders who make significant impacts in their communities.
                </p>
              </div>
            </div>

            {/* Story 3 */}
            <div className="transition-transform transform hover:scale-105 duration-300">
              <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                <h3 className="text-xl font-semibold mt-4 text-gray-900">Empowering Through Community Service</h3>
                <p className="mt-2 text-gray-600 leading-relaxed">
                  The foundation organized a large-scale clean-up drive in Lagos, bringing together a significant number of volunteers. This initiative not only beautified the neighborhood but also fostered community engagement and environmental awareness. The success of the event demonstrated the foundation's commitment to environmental stewardship and community collaboration.
                </p>
              </div>
            </div>

            {/* Story 4 */}
            <div className="transition-transform transform hover:scale-105 duration-300">
              <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                <h3 className="text-xl font-semibold mt-4 text-gray-900">Prison Outreach Impact</h3>
                <p className="mt-2 text-gray-600 leading-relaxed">
                  An inmate at a local facility found new hope and purpose through the foundation’s prison outreach program. Regular visits from volunteers and the provision of spiritual support helped him reflect on his life and seek redemption. Now engaged in a rehabilitation program, he is preparing for reintegration into society, illustrating the program's potential for personal transformation.
                </p>
              </div>
            </div>

            {/* Story 5 */}
            <div className="transition-transform transform hover:scale-105 duration-300">
              <div className="bg-white p-6 shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
                <h3 className="text-xl font-semibold mt-4 text-gray-900">Educational Advancement</h3>
                <p className="mt-2 text-gray-600 leading-relaxed">
                  A scholarship recipient who began her studies in 2023 is pursuing an Engineering degree at the Federal University of Technology. With the foundation’s support, she has excelled in her studies and secured an internship with a leading tech company. Her success story underscores the foundation’s role in nurturing future leaders in STEM fields and creating opportunities for career advancement.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default ProgramsPage;
