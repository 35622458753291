import React from 'react';

function Loader() {
  return (
    <div className="flex items-center justify-center h-screen bg-blue-900 text-white">
      <div className="text-2xl font-bold animate-pulse">
        Rev Vitalis Chukwuemeka Foundations
      </div>
    </div>
  );
}

export default Loader;
