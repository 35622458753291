import React from 'react';

function GetInvolvedPage() {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section className="bg-blue-900 text-white py-16 mt-4">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-5xl font-bold">Get Involved</h1>
          <p className="mt-4 text-lg">Discover how you can contribute and make a difference.</p>
        </div>
      </section>

      {/* Volunteer Opportunities Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-10 text-blue-900">Volunteer Opportunities</h2>
          <div className="flex flex-wrap -mx-4">
            {/* Volunteer Card 1 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://images.unsplash.com/photo-1616680214084-22670de1bc82?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dm9sdW50ZWVyfGVufDB8fDB8fHww"
                  alt="Volunteer"
                  className="w-full h-64 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4 text-blue-900">Be a Volunteer</h3>
                <p className="mt-2 text-gray-700">
                  Join us in various volunteer programs and help make a difference in your community.
                  <br />
                  <span className='font-bold text-blue-900'>
                    WISH TO BE PART?
                    <br />
                    Kindly send an email to 
                    <a href="mailto:rvcofoundations@gmail.com" className="text-blue-600 underline"> rvcofoundations@gmail.com</a>
                  </span>
                </p>
              </div>
            </div>

            {/* Volunteer Card 2 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://images.unsplash.com/photo-1560089168-4169937e37d5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8c3BlY2lhbCUyMHByb2plY3RzfGVufDB8fDB8fHww"
                  alt="Volunteer 2"
                  className="w-full h-64 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4 text-blue-900">Special Projects</h3>
                <p className="mt-2 text-gray-700">
                  Get involved in specific community projects to bring positive change.
                  <br />
                  <span className='font-bold text-blue-900'>
                    WISH TO BE PART?
                    <br />
                    Kindly send an email to 
                    <a href="mailto:rvcofoundations@gmail.com" className="text-blue-600 underline"> rvcofoundations@gmail.com</a>
                  </span>
                </p>
              </div>
            </div>

            {/* Volunteer Card 3 */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img
                  src="https://images.unsplash.com/photo-1503428593586-e225b39bddfe?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGNvbW11bml0eSUyMGVkdWNhdGlvbnxlbnwwfHwwfHx8MA%3D%3D"
                  alt="Community Education"
                  className="w-full h-64 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mt-4 text-blue-900">Community Education</h3>
                <p className="mt-2 text-gray-700">
                  Join us in providing educational support and resources to underprivileged children and adults in our community.
                  <br />
                  <span className='font-bold text-blue-900'>
                    WISH TO BE PART?
                    <br />
                    Kindly send an email to 
                    <a href="mailto:rvcofoundations@gmail.com" className="text-blue-600 underline"> rvcofoundations@gmail.com</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Donate Section */}
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-6 text-blue-900">Make a Difference</h2>
          <div className="text-center mb-8">
            <p className="text-lg text-gray-700 mb-4">
              Your generous donations can create a significant impact in someone's life. 
              We deeply appreciate any support you can offer.
            </p>
          </div>
          <div className="flex justify-center">
            <a href="/donate" className="bg-yellow-500 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-yellow-600 transition duration-300 transform hover:scale-105">
              Donate Now
            </a>
          </div>
          <div className="text-center mt-8">
            <p className="text-sm text-gray-500">
              Every contribution, no matter the size, helps us to continue our mission. Thank you for your support!
            </p>
          </div>
        </div>
      </section>

      {/* Upcoming Events Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-8">Upcoming Events</h2>
          <div className="flex flex-wrap -mx-4">
            {/* Event 1 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img src="https://th.bing.com/th/id/OIP.pGCDKeB74GdedDK44P-vbwHaFj?w=236&h=180&c=7&r=0&o=5&pid=1.7" alt="Event 1" className="w-full h-48 object-cover rounded-md" />
                <h3 className="text-xl font-semibold mt-4">Charity Run 2024</h3>
                <p className="mt-2 text-gray-600">
                Get ready to hit the track for our Charity Run, aimed at raising funds to support our vital programs. Whether you're a seasoned runner or a casual walker, your participation will help fuel our initiatives and make a positive impact. Join us to get active and support a great cause!                  
                <br />
                <br />
                  <span className='font-bold text-blue-900'>WISH TO BE PART? <br /> Kindly send an email to <a href="mailto:info@revvitalisfoundations.org" className="text-blue-600 underline">info@revvitalisfoundations.org</a></span>
                </p>
              </div>
            </div>
            {/* Event 2 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img src="/assets/community.jpeg" alt="Event 2" className="w-full h-48 object-cover rounded-md" />
                <h3 className="text-xl font-semibold mt-4">Upcoming Community Outreach Event</h3>
                <p className="mt-2 text-gray-600">
                The Rev Vitalis Chukwuemeka Onah Foundation will host a community outreach event focused on supporting local families in need by providing essential resources and information. Join us as we make a direct impact in our community!                  
                  <br />
                  <br />
                  <span className='font-bold text-blue-900'>WISH TO BE PART? <br /> Kindly send an email to <a href="mailto:rvcofoundations@gmail.com" className="text-blue-600 underline">rvcofoundations@gmail.com</a></span>
                </p>
              </div>
            </div>
            {/* Event 3 */}
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 duration-300">
                <img src="https://images.unsplash.com/photo-1563986768609-322da13575f3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGZ1bmQlMjByYWlzaW5nfGVufDB8fDB8fHww" alt="Event 3" className="w-full h-48 object-cover rounded-md" />
                <h3 className="text-xl font-semibold mt-4">Fundraising Gala</h3>
                <p className="mt-2 text-gray-600">
                Join us for our annual Fundraising Gala, an elegant evening featuring dinner, entertainment, and inspiring speakers. All proceeds will support our ongoing programs and initiatives.                  
                  <br />
                  <br />
                  <br />
                  <br />
                  <span className='font-bold text-blue-900'>WISH TO BE PART? <br /> Kindly send an email to <a href="mailto:rvcofoundations@gmail.com" className="text-blue-600 underline">rvcofoundations@gmail.com</a></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Community Impact Section */}
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold text-blue-900 mb-4">Our Impact</h2>
          <p className="text-lg text-gray-700 mb-8">
            Over the years, we have made significant strides in the community, and we want to share our achievements with you:
          </p>
          <div className="flex flex-wrap justify-center">
            {/* Impact Card 1 */}
            <div className="w-full md:w-1/4 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
                <h3 className="text-4xl font-bold text-blue-900">20+</h3>
                <p className="text-gray-600">Families Supported</p>
              </div>
            </div>
            {/* Impact Card 2 */}
            <div className="w-full md:w-1/4 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
                <h3 className="text-4xl font-bold text-blue-900">20+</h3>
                <p className="text-gray-600">Meals Distributed</p>
              </div>
            </div>
            {/* Impact Card 3 */}
            <div className="w-full md:w-1/4 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
                <h3 className="text-4xl font-bold text-blue-900">15+</h3>
                <p className="text-gray-600">Educational Supplies Distributed</p>
              </div>
            </div>
            {/* Impact Card 4 */}
            <div className="w-full md:w-1/4 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
                <h3 className="text-4xl font-bold text-blue-900">10+</h3>
                <p className="text-gray-600">Community Events Held</p>
              </div>
            </div>
          </div>
        </div>
      </section>  

      {/* FAQ Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-8 text-blue-900">Frequently Asked Questions</h2>
          <div className="space-y-4">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">How can I volunteer?</h3>
              <p className="mt-2 text-gray-600">You can volunteer by sending us an email at <a href="mailto:rvcofoundations@gmail.com" className="text-blue-600 underline">rvcofoundations@gmail.com</a> to get started.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">What types of donations do you accept?</h3>
              <p className="mt-2 text-gray-600">We accept monetary donations, food supplies, educational materials, and clothing. Please contact us for specific needs.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">How can I stay updated on your activities?</h3>
              <p className="mt-2 text-gray-600">Follow us on our social media platforms or subscribe to our newsletter for the latest updates.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GetInvolvedPage;
