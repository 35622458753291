import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('your-publishable-key-here');

function DonatePage() {
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);

  const handleDonateOnlineClick = () => {
    setPaymentModalOpen(true);
  };

  const handleOverlayClick = (event) => {
    // Close modal when clicking on overlay
    if (event.target.classList.contains('overlay')) {
      setPaymentModalOpen(false);
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-extrabold text-center mb-6 text-blue-900">Support Us with Your Donation</h2>
        <p className="text-center text-lg text-gray-700 mb-10">
          Your contributions make a significant difference in the lives of those we serve. Help us continue our mission by making a donation today.
        </p>

        <div className="max-w-xl mx-auto bg-white p-10 shadow-xl rounded-lg border border-gray-200 mb-12">
          <h3 className="text-2xl font-semibold mb-6">Donate via Bank Transfer</h3>
          <p className="text-gray-800 mb-4">
            <strong>Bank Name:</strong> FCMB (First City Monument Bank PLC)
          </p>
          <p className="text-gray-800 mb-4">
            <strong>Account Number:</strong> 2001684929
          </p>
          <p className="text-gray-800 mb-8">
            <strong>Account Name:</strong> Reverend Vitalis Chukwuemeka Onah Foundation
          </p>

          <hr className="my-6 border-gray-300" />

          <h3 className="text-2xl font-semibold mb-4">Donate Using Online Transfer</h3>
          <p className="text-gray-800 mb-6">
            You can also make a donation using our secure online payment system. Click the button below to get started:
          </p>
          <button
            onClick={handleDonateOnlineClick}
            className="inline-block bg-blue-900 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300 text-center font-semibold shadow-md"
            aria-label="Donate Online"
          >
            Donate Online
          </button>
        </div>

        {/* Impact Section */}
        <div className="max-w-3xl mx-auto bg-white p-10 shadow-xl rounded-lg border border-gray-200 mb-12">
          <h3 className="text-2xl font-semibold mb-6">Your Impact</h3>
          <p className="text-gray-700 mb-4">
            With your donations, we have been able to:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>Provide scholarships to underprivileged students.</li>
            <li>Conduct community outreach programs that assist the needy.</li>
            <li>Offer mentorship and educational resources to empower individuals.</li>
            <li>Support local initiatives that promote social justice and equality.</li>
          </ul>
          <p className="text-gray-700 mb-4">
            Your support enables us to continue these vital services and reach more individuals in need.
          </p>
        </div>

        {/* Testimonials Section */}
        <div className="max-w-3xl mx-auto bg-white p-10 shadow-xl rounded-lg border border-gray-200 mb-12">
          <h3 className="text-2xl font-semibold mb-6">What Our Supporters Say</h3>
          <blockquote className="border-l-4 border-blue-900 pl-4 italic text-gray-600 mb-4">
            "Donating to Rev Vitalis Chukwuemeka Onah Foundation has been one of the most fulfilling experiences of my life. I can see the impact of my contribution firsthand."
          </blockquote>
          <p className="text-gray-700 mb-4">— Jane Doe, Donor</p>

          <blockquote className="border-l-4 border-blue-900 pl-4 italic text-gray-600 mb-4">
            "The scholarship I received from the foundation changed my life. I'm now able to pursue my dreams thanks to generous donors like you."
          </blockquote>
          <p className="text-gray-700 mb-4">— John Smith, Scholarship Recipient</p>
        </div>

        {/* FAQs Section */}
        <div className="max-w-3xl mx-auto bg-white p-10 shadow-xl rounded-lg border border-gray-200">
          <h3 className="text-2xl font-semibold mb-6">Frequently Asked Questions</h3>
          <div className="mb-4">
            <h4 className="font-semibold text-gray-800">1. How will my donation be used?</h4>
            <p className="text-gray-600">
              Your donation will directly support our programs aimed at education, community outreach, and empowerment.
            </p>
          </div>
          <div className="mb-4">
            <h4 className="font-semibold text-gray-800">2. Are my donations tax-deductible?</h4>
            <p className="text-gray-600">
              Yes, all donations are tax-deductible as we are a registered nonprofit organization.
            </p>
          </div>
          <div className="mb-4">
            <h4 className="font-semibold text-gray-800">3. Can I specify where my donation goes?</h4>
            <p className="text-gray-600">
              Absolutely! You can choose to direct your donation towards specific programs or initiatives.
            </p>
          </div>
        </div>

        {/* Final Call to Action */}
        <div className="mt-12 text-center">
          <h4 className="text-xl font-bold text-gray-800 mb-4">Why Your Donation Matters</h4>
          <p className="text-gray-600 mb-6">
            Every donation, no matter the amount, helps us provide essential services and support to our community. Together, we can make a lasting impact!
          </p>
          <button
            className="inline-block bg-blue-900 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300 text-center font-semibold shadow-md"
            onClick={handleDonateOnlineClick}
            aria-label="Donate Now"
          >
            Donate Now
          </button>
        </div>

        {/* Payment Modal */}
        {isPaymentModalOpen && (
          <div 
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 overlay" 
            onClick={handleOverlayClick}
          >
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg mx-auto">
              <h3 className="text-2xl font-semibold mb-4">Complete Your Donation</h3>
              <Elements stripe={stripePromise}>
                <PaymentForm onClose={() => setPaymentModalOpen(false)} />
              </Elements>
              <button
                className="mt-4 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
                onClick={() => setPaymentModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DonatePage;
