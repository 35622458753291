import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-blue-900 text-white shadow-md fixed top-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center py-6 px-6">
        {/* Logo */}
        <div className="flex items-center">
          <img src="/assets/RVCOF-logo.jpg" alt="Logo" className="h-8 mr-3" />
          <div className="text-2xl font-bold">
            <a href="/">RVCOF</a>
          </div>
        </div>

        {/* Hamburger Menu for Mobile & Tablet */}
        <button
          className="md:block lg:hidden focus:outline-none" // Hide on large screens
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
        </button>

        {/* Links - Hidden on mobile, visible on larger screens */}
        <nav className="hidden lg:flex items-center space-x-6">
          <a href="/" className="hover:text-yellow-500">Home</a>
          <a href="/about-us" className="hover:text-yellow-500">About Us</a>
          <a href="/programs" className="hover:text-yellow-500">Programs</a>
          <a href="/get-involved" className="hover:text-yellow-500">Get Involved</a>
          <a href="/resources" className="hover:text-yellow-500">Resources</a>
          <a href="/news-updates" className="hover:text-yellow-500">News & Updates</a>
          <a href="/contact-us" className="hover:text-yellow-500">Contact</a>
        </nav>
      </div>

      {/* Mobile/Tablet Menu */}
      {isOpen && (
        <div className="md:block lg:hidden bg-blue-800">
          <nav className="flex flex-col space-y-4 py-4 text-center">
            <a href="/" className="hover:text-yellow-500" onClick={toggleMenu}>Home</a>
            <a href="/about-us" className="hover:text-yellow-500" onClick={toggleMenu}>About Us</a>
            <a href="/programs" className="hover:text-yellow-500" onClick={toggleMenu}>Programs</a>
            <a href="/get-involved" className="hover:text-yellow-500" onClick={toggleMenu}>Get Involved</a>
            <a href="/resources" className="hover:text-yellow-500" onClick={toggleMenu}>Resources</a>
            <a href="/news-updates" className="hover:text-yellow-500" onClick={toggleMenu}>News & Updates</a>
            <a href="/contact-us" className="hover:text-yellow-500" onClick={toggleMenu}>Contact</a>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
