import React from 'react';

function PrivacyPolicyTerms() {
  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        {/* Privacy Policy Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Privacy Policy ✔</h2>
          <div className="max-w-3xl mx-auto bg-white p-8 shadow-lg rounded-lg">
            <br />
            <h3 className="text-xl font-semibold mb-4">1️⃣ How We Handle Your Data</h3>
            <br />
            <p className="text-gray-700 mb-4">
              *___*  We respect your privacy and are committed to protecting your personal information. We may collect, store, and use
              information that you provide when you visit our website or interact with our services. This includes your name,
              contact information, and other relevant data.
            </p>
            <br />
            <h3 className="text-xl font-semibold mb-4">2️⃣ Data Protection</h3>
            <br />
            <p className="text-gray-700">
              *___*  Your data will not be shared with third parties without your consent unless required by law. We use appropriate
              security measures to protect your personal information from unauthorized access or disclosure.
            </p>
            <br />
            <br />
            <br />
          </div>
        </section>

        {/* Terms of Service Section */}
        <section>
            <br />
            <br />
          <h2 className="text-3xl font-bold text-center mb-8">Terms of Service ✔</h2>
          <div className="max-w-3xl mx-auto bg-white p-8 shadow-lg rounded-lg">
            <br />
            <h3 className="text-xl font-semibold mb-4">1️⃣ Website Usage Rules</h3>
            <br />
            <p className="text-gray-700 mb-4">
              By accessing or using this website, you agree to comply with the following rules and guidelines:
            </p>
                <br />
              <div>*___*  You may not use the website for any illegal activities.</div>
              <br />
              <div>*___*  You agree not to disrupt the functioning of the website.</div>
              <br />
              <div>*___*  Content on the site is for informational purposes and may not be redistributed without permission.</div>
              <br />
            <h3 className="text-xl font-semibold mb-4">2️⃣ Limitation of Liability</h3>
            <br />
            <p className="text-gray-700">
              *___*  We are not responsible for any damages or losses related to the use of this website. We reserve the right to modify
              or discontinue the site at any time.
            </p>
            <br />
            <br />
            <br />
          </div>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicyTerms;
