import React, { useState, useEffect } from 'react';
import { PaystackButton } from 'react-paystack';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PaymentForm = ({ onClose }) => {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showPayButton, setShowPayButton] = useState(false); // Control button visibility

  // Check form validity
  useEffect(() => {
    const isFormValid = email !== '' && amount > 0;
    setShowPayButton(isFormValid); // Show Paystack button if the form is valid
  }, [email, amount]);

  const handlePaystackSuccessAction = async (reference) => {
    try {
      const response = await axios.post('https://rvcof-backend.onrender.com/api/verify-transaction', { reference: reference.reference });

      if (response.status === 200) {
        setMessage(response.data.message || 'Transaction verified successfully'); // Use default message if none provided
        setMessageType('success');
        toast.success(response.data.message || 'Transaction verified successfully'); // Toast success message
        setEmail(''); // Clear form
        setAmount(0); // Clear form
        onClose(); // Close the form
      } else {
        // Handle unexpected status codes
        setMessage('Transaction verification failed. Please try again.');
        setMessageType('error');
        toast.error('Transaction verification failed. Please try again.'); // Toast error message
      }
    } catch (error) {
      console.error('Transaction verification failed:', error); // Log the error for debugging
      const errorMessage = error.response?.data.message || 'Transaction verification failed due to an unexpected error. Please try again.';
      setMessage(errorMessage);
      setMessageType('error');
      toast.error(errorMessage); // Toast error message
    }
  };

  const handlePaystackCloseAction = () => {
    const closeMessage = 'Payment modal closed. You can try again.';
    setMessage(closeMessage);
    setMessageType('error');
    toast.error(closeMessage); // Toast error message on close
  };

  const componentProps = {
    email: email,
    amount: amount > 0 ? amount * 100 : 0, // Amount in kobo
    publicKey,
    text: 'Pay Now',
    onSuccess: handlePaystackSuccessAction,
    onClose: handlePaystackCloseAction,
  };

  // Prevent default form submission when the Paystack button is clicked
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            className="w-full px-4 py-2 rounded-lg border border-gray-300"
            required
          />
        </div>

        {/* Instructional Note */}
        <p className="text-center text-red-500 mb-4">
          * Please complete the form. The Pay Now button will appear once the information is valid.
        </p>

        {/* Success or error messages based on transaction verification */}
        {message && (
          <p className={`text-center ${messageType === 'success' ? 'text-green-500' : 'text-red-500'}`}>
            {message}
          </p>
        )}

        {/* Slide-in effect for the Paystack button */}
        <div
          className={`transition-opacity duration-500 ${showPayButton ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        >
          {showPayButton && (
            <PaystackButton
              {...componentProps}
              className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            />
          )}
        </div>
      </form>

      {/* ToastContainer for notifications */}
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
    </>
  );
};

export default PaymentForm;
