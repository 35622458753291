import React from 'react';

// Reusable components
const SectionTitle = ({ title }) => (
  <h2 className="text-4xl font-bold text-center mb-12 text-gray-800">{title}</h2>
);

const TextBlock = ({ title, text }) => (
  <div className="w-full md:w-1/2 px-6 mb-12 md:mb-0">
    <h3 className="text-2xl font-semibold text-gray-800 mb-4">{title}</h3>
    <p className="text-lg text-gray-600 leading-relaxed">{text}</p>
  </div>
);

const TeamMember = ({ name, img }) => (
  <div className="w-full md:w-1/3 px-4 mb-8 team-card transform transition duration-300 hover:-translate-y-2">
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center hover:shadow-xl">
      <img src={img} alt={name} className="w-32 h-32 rounded-full mx-auto mb-4 object-cover team-image" />
      <h3 className="text-xl font-semibold leading-tight">{name}</h3>
    </div>
  </div>
);

const PastWork = ({ title, img, tag }) => (
  <div className="relative group">
    <img
      src={img}
      alt={title}
      className="w-full h-64 object-cover rounded-lg shadow-lg transition-transform duration-300 transform group-hover:scale-105 group-hover:brightness-75"
    />
    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col items-center justify-center">
      <h3 className="text-white text-xl font-bold mb-2 transform translate-y-8 group-hover:translate-y-0 transition-transform duration-300">
        {title}
      </h3>
    </div>
    <span className="absolute bottom-2 left-2 bg-blue-500 text-white text-xs px-2 py-1 rounded">{tag}</span>
  </div>
);

// Main AboutUs Component
function AboutUs() {
  return (
    <div className="bg-gray-50 min-h-screen">

      <section className="bg-blue-900 text-white py-16 mt-4">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold">About us</h1>
        </div>
      </section>

      {/* Mission & Vision */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-6 text-center">
          <SectionTitle title="Our Mission and Vision" />

          <div className="flex flex-wrap justify-center gap-16 mt-12">
            <TextBlock
              title="Our Mission"
              text="We are committed to uplifting individuals by providing resources and opportunities that nurture personal growth, deepen faith, and build meaningful connections. By addressing both spiritual and practical needs, we aim to create a resilient support network that promotes social justice and fosters a vibrant, inclusive community."
            />
            <TextBlock
              title="Our Vision"
              text="Our vision is to serve as a beacon of hope and transformation. We strive to empower individuals and communities through spiritual growth, compassionate outreach, and holistic support, cultivating a world where faith and unity drive positive, lasting change."
            />
          </div>
        </div>
      </section>

      {/* History */}
      <section className="py-20 bg-gray-100">
        <div className="container mx-auto px-6">
          <SectionTitle title="History and Impact" />
          <div className="flex flex-wrap justify-center text-center mt-10">
            <div className="w-full md:w-2/3 lg:w-1/2">
              <p className="text-gray-700 text-lg leading-relaxed mb-8">
                Founded in 2014 by Pastor Rev Vitalis Chukwuemeka Onah, the Rev Vitalis Chukwuemeka Onah Foundation’s Scholarship Program was created to expand educational access for underprivileged students. With a vision of financial support and mentorship, Rev Vitalis aimed to make higher education attainable for students facing financial challenges.
              </p>
              <p className="text-gray-700 text-lg leading-relaxed mb-8">
                Starting with a modest group of recipients, the program quickly gained traction as it transformed lives. Over time, it has grown significantly, now supporting more students each year and broadening its services to include academic mentorship and career guidance.
              </p>
              <p className="text-gray-700 text-lg leading-relaxed mb-8">
                Today, the program continues Rev Vitalis’s mission of educational empowerment, enabling students to achieve their aspirations and positively impact their communities.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Founder Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <SectionTitle title="Meet Our Founder" />
          <div className="flex flex-wrap items-center justify-center mb-8">
            <div className="w-full md:w-1/2 lg:w-1/3 px-4">
              <img
                src='/assets/daddy.JPG'
                alt="Rev Vitalis Chukwuemeka"
                className="mx-auto mb-6 rounded-lg shadow-xl hover:shadow-2xl transform hover:scale-105 transition duration-300 object-cover"
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center text-center -mx-4">
            <div className="w-full md:w-3/4 lg:w-2/3 px-4">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">Rev Vitalis Chukwuemeka</h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                Rev Vitalis Chukwuemeka is a visionary leader and the driving force behind the foundation. With over 17 years of experience in community service and development, he has dedicated his life to empowering underserved populations through education, health initiatives, and grassroots programs.
              </p>
              <p className="text-gray-600 text-lg leading-relaxed mt-4">
                His journey began several years ago, when he recognized the growing need for accessible resources in marginalized communities. Through his passion and commitment, the foundation has launched numerous initiatives that have impacted thousands of lives. His leadership philosophy is grounded in empathy, service, and creating sustainable change for future generations.
              </p>
              <p className="text-gray-600 text-lg leading-relaxed mt-4">
                Rev Vitalis believes in the power of collaboration and has partnered with various organizations to extend the reach and impact of the foundation’s work. His tireless efforts continue to inspire both the team and the communities they serve.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <SectionTitle title="Meet Our Team" />
          <div className="flex flex-wrap -mx-4">
            {[
              { name: 'Pst. Ihedioha Chinedu', img: '/assets/chinedu.jpg' },
              { name: 'Pst. Iwuagwu Roland', img: '/assets/roland.jpg' },
              { name: 'Pst. Asogwa Ikechukwu', img: '/assets/IK.jpg' },
            ].map((member, index) => (
              <TeamMember key={index} name={member.name} img={member.img} />
            ))}
          </div>
        </div>
      </section>

      {/* Past Works. */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <SectionTitle title="Some of Our Past Works" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { title: 'Jamb lesson / tutorials', img: '/assets/jamb.jpg', tag: 'Education' },
              { title: 'Scholarship Awards Ceremony', img: 'https://images.unsplash.com/photo-1533854775446-95c4609da544?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHNjaG9sYXJzaGlwJTIwYXdhcmQlMjBjZXJlbW9ueXxlbnwwfHwwfHx8MA%3D%3D', tag: 'Award' },
              { title: 'Prison Outreach', img: 'https://th.bing.com/th/id/OIP.5_WsOG7V1VwTQVCmh3As2wAAAA?rs=1&pid=ImgDetMain', tag: 'Outreach' },
              { title: 'Food Distribution Program', img: 'https://th.bing.com/th/id/OIP.1bSFzyKC-dOslWj758ESbgHaE8?w=247&h=180&c=7&r=0&o=5&pid=1.7', tag: 'Support' },
              { title: 'Jamb Lessons', img: '/assets/jamb2.jpg', tag: 'Education' },
              { title: 'Jamb, tutorials, classes', img: '/assets/jamb3.jpg', tag: 'Education' },
            ].map((work, index) => (
              <PastWork key={index} title={work.title} img={work.img} tag={work.tag} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
