import React, { useState } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Send form data to backend
      const response = await axios.post('https://rvcof-backend.onrender.com/api/contact', formData);
  
      if (response.status === 200) {
        toast.success("Your message has been sent successfully! We’ll get back to you soon.");
      }
    } catch (error) {
      console.error('Submission error:', error);
      const errorMessage = error.response?.data?.message || "There was an error submitting your message. Please try again.";
      toast.error(errorMessage);
    }

    // Clear form after submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        {/* Toast notification */}
        <ToastContainer />

        {/* Contact Form Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Get In Touch</h2>
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 shadow-lg rounded-lg">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                YOUR NAME
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                aria-label="Your name"
                placeholder="Enter your name"
                className="w-full px-4 py-2 rounded-lg border border-gray-300 text-gray-900"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                YOUR EMAIL
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                aria-label="Your email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 rounded-lg border border-gray-300 text-gray-900"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                MESSAGE
              </label>
              <textarea
                id="message"
                value={formData.message}
                onChange={handleChange}
                aria-label="Your message"
                placeholder="Enter your message"
                className="w-full px-4 py-2 rounded-lg border border-gray-300 text-gray-900"
                rows="5"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-blue-900 text-white py-2 px-6 rounded-lg hover:bg-blue-700 transition duration-300 w-full"
            >
              Send Message
            </button>
          </form>
        </section>

        {/* Contact Information Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Contact Information</h2>
          <div className="text-center">
            <p className="text-gray-700 mb-4">
              <strong>Phone:</strong> +234 805 668 3285
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Email:</strong> rvcofoundations@gmail.com
            </p>
            <p className="text-gray-700">
              <strong>Address:</strong> 2, ECWA CRESCENT,<br />
              off Okulaja Street, opposite PEDRO POLICE STATION, Somolu, Lagos 100233, Lagos, Nigeria
            </p>
          </div>
        </section>

        {/* Social Media Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Follow Us</h2>
          <div className="flex justify-center space-x-8">
            <a
              href="https://www.facebook.com/profile.php?id=61565208497459"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-blue-900 transition duration-300"
              aria-label="Facebook"
            >
              <FaFacebookF className="text-2xl" />
            </a>
            <a
              href="https://x.com/rvcofoundations"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-blue-900 transition duration-300"
              aria-label="Twitter"
            >
              <FaTwitter className="text-2xl" />
            </a>
            <a
              href="https://www.instagram.com/rev.vitalisonahfoundations"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-blue-900 transition duration-300"
              aria-label="Instagram"
            >
              <FaInstagram className="text-2xl" />
            </a>
            <a
              href="https://www.linkedin.com/in/rvco-foundations/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-blue-900 transition duration-300"
              aria-label="LinkedIn"
            >
              <FaLinkedin className="text-2xl" />
            </a>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactUs;
