import React from 'react';
import FAQs from './FAQ';

function ResourcesPage() {
  return (
    <div>
      <section className="bg-blue-900 text-white py-16 mt-4">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-bold">Our Resources</h1>
        </div>
      </section>

      {/* Community Programs Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">Community Programs</h2>
          <div className="space-y-4">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">Food Distribution Program</h3>
              <p className="mt-2 text-gray-600">Our Food Distribution Program provides nutritious meals to families in need every week. Join us in making a difference!</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">Youth Empowerment Workshops</h3>
              <p className="mt-2 text-gray-600">We offer workshops designed to empower youth with skills in leadership, entrepreneurship, and personal development.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">Health Awareness Campaigns</h3>
              <p className="mt-2 text-gray-600">Our health campaigns aim to educate communities about preventive health measures and available resources.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Success Stories Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">Success Stories</h2>
          <div className="space-y-4">
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">A Journey of Transformation</h3>
              <p className="mt-2 text-gray-600">“Thanks to the community programs, I was able to get the support I needed to start my own small business. I am now able to support my family and give back to others!”</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">A story of Transformation</h3>
              <p className="mt-2 text-gray-600">“The workshops helped me gain confidence and leadership skills. I am now volunteering to help others in my community.”</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold">A Journey of Empowerment</h3>
              <p className="mt-2 text-gray-600">“Through the foundation’s mentorship program, I developed new skills and discovered my potential. Now, I’m paying it forward by mentoring others in my community.”</p>
            </div>
          </div>
        </div>
      </section>

      {/* Volunteer Opportunities Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">Volunteer Opportunities</h2>
          <p className="text-lg text-center mb-4">Join us in making a difference! We have various volunteer positions available.</p>
          <ul className="list-disc list-inside space-y-2">
            <li><strong>Food Distribution Volunteer:</strong> Help pack and distribute food to families in need.</li>
            <li><strong>Event Coordinator:</strong> Assist in organizing community events and workshops.</li>
            <li><strong>Social Media Ambassador:</strong> Promote our programs and events on social media platforms.</li>
          </ul>
          <div className="text-center mt-4">
            <a href="mailto:rvcofoundations@gmail.com" className="inline-block bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-blue-800">Apply Now</a>
          </div>
        </div>
      </section>

      {/* Events Calendar Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">Upcoming Events</h2>
          <ul className="list-disc list-inside space-y-2">
            <li><strong>Community Health Fair:</strong> Join us on March 15, 2024, for a day of health screenings and education.</li>
            <li><strong>Annual Fundraising Gala:</strong> Save the date for our gala on April 20, 2024, to support our programs.</li>
            <li><strong>Volunteer Orientation:</strong> Learn how to get involved on May 5, 2024, at our office.</li>
          </ul>
        </div>
      </section>

      {/* FAQs Section */}
      <FAQs />
    </div>
  );
}

export default ResourcesPage;
