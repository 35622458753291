import React, { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import HomePage from './components/HomePage';
import AboutUs from './components/About';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import Loader from './components/Loader';
import ProgramsPage from './components/ProgramsPage';
import GetInvolvedPage from './components/GetInvolvedPage';
import ResourcesPage from './components/Resources/ResourcesPage';
import NewsUpdates from './components/NewsUpdates';
import ContactUs from './components/ContactUs';
import PrivacyPolicyTerms from './components/PrivacyPolicyTerms';
import SiteMap from './components/SiteMap';
import DonatePage from './components/DonatePage';
import ScrollToTop from './components/ScrollToTop';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={500}
      >
        <div className="page-transition">
          <Routes location={location}>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/programs" element={<ProgramsPage />} />
            <Route path="/get-involved" element={<GetInvolvedPage />} />
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/news-updates" element={<NewsUpdates />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy-terms" element={<PrivacyPolicyTerms />} />
            <Route path="/site-map" element={<SiteMap />} />
            <Route path="/donate" element={<DonatePage />} />
          </Routes>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Header />
      <AnimatedRoutes />
      <Footer />
      <ScrollToTop />
    </Suspense>
  );
}

export default App;
