import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewsUpdates() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  
  // Array of blog posts data
  const blogPosts = [
    {
      title: 'Community Outreach',
      description: 'Discover our latest achievement as we take meaningful steps towards making a difference in our community. Our outreach programs aim to provide essential resources, education, and support to underserved populations. From food drives to health clinics, we are committed to addressing the most pressing needs within our community.',
      image: 'https://th.bing.com/th/id/OIP.r7SFtLLqAxWddhTTVGBjZwHaDt?w=338&h=174&c=7&r=0&o=5&pid=1.7',
    },
    {
      title: 'Scholarship Program',
      description: 'Our Scholarship program empowers students in our community by providing financial assistance for their educational pursuits. We aim to level the playing field by removing financial barriers, allowing students to focus on their studies. The initiative awards funds based on academic achievements, community involvement, and personal circumstances, ensuring education is accessible to all.',
      image: 'https://th.bing.com/th/id/OIP.qmdS3jkGGf9_lDdKMbUcigHaE7?w=259&h=180&c=7&r=0&o=5&pid=1.7',
    },
    {
      title: 'Prison Outreach',
      description: 'Our Prison Outreach program provides support, education, and rehabilitation opportunities to incarcerated individuals. We believe everyone deserves a second chance, and we aim to help inmates reintegrate into society through life skills training and mentorship, empowering them to make positive life changes.',
      image: 'https://th.bing.com/th/id/OIP._ABx0ST44zAtycMViWyi9QHaED?w=312&h=180&c=7&r=0&o=5&pid=1.7',
    },
  ];

  // Upcoming events data (no dates, rephrased)
  const upcomingEvents = [
    {
      title: 'Community Health Fair',
      description: 'Join us for a day of free health screenings, wellness workshops, and educational resources for families. This event aims to promote health awareness and support within our community, Just send us a mail and we will keep in touch or reach out through the contact us page.',
    },
    {
      title: 'Annual Fundraising Gala',
      description: 'Help us raise funds to support our outreach programs. Enjoy a night of dinner, entertainment, and community bonding, while making a difference through your contributions, Just send us a mail and we will keep in touch or reach out through the contact us page.',
    },
    {
      title: 'Volunteer Day',
      description: 'Come and lend a hand! We’re looking for volunteers to assist with various community projects. Together, we can create meaningful change and make a difference in the lives of others, Just send us a mail and we will keep in touch or reach out through the contact us page.',
    },
  ];

  // Testimonials data (names replaced)
  const testimonials = [
    {
      name: 'Participant 1',
      feedback: 'The Scholarship program changed my life. I can now pursue my dreams of becoming a nurse without financial worries. Thank you for your support!',
    },
    {
      name: 'Participant 2',
      feedback: 'The Prison Outreach program gave me the tools I needed to turn my life around. I am now working and giving back to the community.',
    },
    {
      name: 'Participant 3',
      feedback: 'Participating in the Community Outreach initiatives made me realize the importance of helping others. I am proud to be a part of such a caring organization.',
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://rvcof-backend.onrender.com/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('Success: ' + data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setEmail('');
      } else {
        toast.error('Error: ' + (data.message || 'Failed to subscribe to newsletter, Please try again later'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('An error occurred, Please try again later', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
      <ToastContainer />
      
        {/* Blog Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Latest Blog Articles</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Map over the blogPosts array to render each blog post */}
            {blogPosts.map((post, index) => (
              <div className="bg-white p-6 shadow-lg rounded-lg" key={index}>
                <img
                  src={post.image}
                  alt={`Blog Post ${index + 1}`}
                  className="w-full h-40 object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                <p className="text-gray-600">{post.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Upcoming Events Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Upcoming Events</h2>
          <div className="space-y-6">
            {upcomingEvents.map((event, index) => (
              <div className="bg-white p-6 shadow-lg rounded-lg" key={index}>
                <h3 className="text-xl font-semibold">{event.title}</h3>
                <p className="text-gray-600">{event.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">What People Are Saying</h2>
          <div className="space-y-6">
            {testimonials.map((testimonial, index) => (
              <div className="bg-white p-6 shadow-lg rounded-lg" key={index}>
                <p className="text-gray-600 italic">"{testimonial.feedback}"</p>
                <p className="text-gray-800 font-semibold mt-2">- {testimonial.name}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Newsletter Signup */}
        <section className="bg-blue-900 py-16 rounded-lg">
          <h2 className="text-3xl font-bold text-white text-center mb-8">Stay Updated</h2>
          <p className="text-center text-white text-lg mb-8">Subscribe to our newsletter to receive the latest updates.</p>
          <div className="max-w-md mx-auto">
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email..."
                className="px-4 py-2 mb-4 text-black bg-white placeholder-gray-500 rounded-lg w-full"
                required
              />
              <button
                type="submit"
                className="bg-yellow-500 text-white py-2 px-8 rounded-lg hover:bg-yellow-600 transition duration-300 w-full"
              >
                Subscribe
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default NewsUpdates;
